import React, { useRef, useState } from 'react';
import styles from '../../../styles/component-styles/admin/upload.module.scss';
import upload_img from "../../../assets/images/upload.svg";
import upload_ic from "../../../assets/icons/upload.svg";
import { PrimaryButton } from "../../general/input/primary-button";
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { constants } from '../../../constants';
import { UploadProgress } from './upload-progress';
import { cancelUploadSongAsync, uploadSongAsync, selectLastId, selectStartingId } from '../../../redux/slice/song/song-slice';
import { toast } from 'react-toastify';

export function Upload() {
    const fileInput = useRef(null);
    const language = useSelector(selectLanguage);
    const [file, setFile] = useState(null);
    const [uploadProgess, setUploadProgress] = useState(false);
    const dispatch = useDispatch();
    const startingId = useSelector(selectStartingId);
    const lastId = useSelector(selectLastId);

    const handleFile = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            
            // Validate file type
            if (!selectedFile.name.endsWith('.csv') || selectedFile.type !== 'text/csv') {
                toast.error(constants[language]?.errors?.invalid_file_type_only_csv_allowed || "Invalid file type. Please upload a CSV file.");
                e.target.value = null; // Reset input value
                return;
            }

            // Cancel the previous upload if needed
            if (file && startingId && lastId) {
                dispatch(cancelUploadSongAsync({ startingId, lastId }));
            }

            setUploadProgress(false);
            setFile(selectedFile);
            setUploadProgress(true);

            // Dispatch the upload action
            dispatch(uploadSongAsync({ file: selectedFile }));
            e.target.value = null; // Reset input value for further uploads
        }
    };

    const onDelete = () => {
        setFile(null);
        setUploadProgress(false);
        dispatch(cancelUploadSongAsync({ startingId, lastId }));
    };

    return (
        <div className={styles.upload}>
            {uploadProgess && <UploadProgress onDelete={onDelete} />}
            <div className={styles.content}>
                <img src={upload_img} alt="Upload" />
                <div className={styles.text}>
                    <h1>{constants[language].start_upload}</h1>
                    <p>{constants[language].upload_description}</p>
                </div>
            </div>
            <div className={styles.uploadBtn} style={{ width: "100%" }}>
                <input
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                    ref={fileInput}
                    accept=".csv" // allowing only CSV files
                    onChange={handleFile}
                />
                <div className={styles.btn} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <PrimaryButton
                        style={{ width: "350px" }}
                        onClick={() => {
                            fileInput.current.click();
                        }}
                    >
                        <img src={upload_ic} alt="Upload Icon" />
                        {!file ? constants[language].upload_btn : constants[language].upload_new_btn}
                    </PrimaryButton>
                </div>
                <div className={styles.mobileBtn} style={{ width: "100%" }}>
                    <PrimaryButton
                        style={{ width: "100%", height: "35px", fontSize: "12px" }}
                        onClick={() => {
                            fileInput.current.click();
                        }}
                    >
                        <img src={upload_ic} alt="Upload Icon" />
                        {!file ? constants[language].upload_btn : constants[language].upload_new_btn}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}

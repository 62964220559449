export const french = {
    upload_music: "Télécharger Musique",
    upload_video: "Télécharger Vidéo",
    songs: "Chansons",
    playlist: "Ma Liste de Chansons",
    videos: "Vidéos",
    search: "Recherche",
    clear: "Effacer",
    ads: "Publicités",
    users: "utilisatrices",
    logout: "Se Déconnecter",
    "Top Searched songs": "Chansons Les Plus Recherchées",
    "upload music": "Télécharger Musique",
    "new songs": "Nouvelles chansons",
    "playlist": "Ma Liste de Chansons",
    "ads": "Publicités",
    "upload video": "Télécharger Vidéo",
    "videos": "Vidéos",
    start_upload: "Commencez par télécharger un fichier",
    upload_description: "Tous les éléments utilisés dans les projets seront stockés ici. Commencez à créer en téléchargeant vos fichiers.",
    upload_btn: "Télécharger",
    no: "N°",
    title: "Titre",
    artist: "Artiste",
    language: "Langue",
    action: "Action",
    add_to_playlist: "Ajouter À Ma Liste de Chansons",
    remove_from_playlist: "Retirer De Ma Liste de Chansons",
    sr: "N°",
    no_data: "Aucune Donnée Trouvée",
    created_on: "Créé Le",
    expired_on: "Expiré Le",
    status: "Statut",
    loading: "Chargement Des Données ...",
    actions: "Actions",
    create_ad: "Créer Une Publicité",
    no_data_search: "Aucune chanson trouvée. Tapez le titre de la chanson ET le nom de l'artiste dans la recherche",
    create_ad_description: "Ajoutez des détails pour publier votre publicité.",
    click_upload: "Cliquez pour télécharger",
    drag_drop: "ou glissez-déposez",
    file_type: "SVG, PNG, JPG ou GIF (max. 800x400px)",
    ad_title: "Titre de la Publicité",
    description: "Description",
    ad_expiry: "Expiration de la Publicité",
    characters: "Caractères",
    cancel: "Annuler",
    publish: "Publier",
    ad_description: "Description de la Publicité",
    active: "Actif",
    close: "Fermé",
    // customer
    home: "Accueil",
    paytip: "Donner Un Pourboire",
    "New Releases": "Nouvelles Sorties",
    recent_added: "Ajouté Récemment",
    close_ad: "Fermer la Publicité",
    "Home": "Accueil",
    "Search Songs": "Rechercher Chansons",
    "Search Playlist": "Rechercher Liste de Chansons",
    "Paytip": "Donner Un Pourboire",
    "Contact Us": "Nous Contacter",
    "legal notice": "Mentions Legales",
    leave_a_tip: "Laissez un pourboire via PayPal",
    leave_a_tip_description: "Soutenez notre application en laissant un pourboire volontaire via PayPal",
    pay_btn: "Payer avec Stripe",
    powered_by: "powered by Felix",
    upload_new_btn: "Télécharger Nouveau",
    thumbnail: "Vignette",
    payment_required: "Pour regarder la vidéo complète, vous devez effectuer un paiement.",
    contact: "Nous Contacter",
    newestToOldest: "Du Plus Récent au Plus Ancien",
    oldestToNewest: "Du Plus Ancien au Plus Récent",
    atoZ: "De A à Z",
    ztoA: "De Z à A",
    sort_by: "Trier Par",
    responsible_for: "Responsable de cette page",
    desclaimer: "Avertissement",
    desclaimer_desc: "Malgré un contrôle soigneux du contenu, nous déclinons toute responsabilité quant au contenu des liens externes. Le contenu des pages liées relève de la seule responsabilité de leurs exploitants.",
    privacy_policy: "Politique de Confidentialité",
    privacy_policy_desc: "Nous prenons très au sérieux la protection de vos données personnelles et respectons strictement toutes les lois et réglementations applicables en matière de protection des données, notamment le Règlement Général sur la Protection des Données (RGPD), la Loi Fédérale sur la Protection des Données (BDSG) et la Loi sur les Télécommunications (TMG). Les explications suivantes donnent un aperçu de la manière dont nous assurons cette protection et des données que nous traitons à quelles fins.",
    usage_data: "Données d'Utilisation",
    usage_data_descriptions: ["Chaque fois que le site est consulté et à chaque fois qu'un fichier est récupéré, des données générales sur ce processus sont automatiquement stockées dans un fichier journal. Le stockage ne sert qu'à des fins système et statistiques (conformément à l'article 6, paragraphe 1, point b) du RGPD) ainsi que, dans des cas exceptionnels, à signaler des infractions pénales (conformément à l'article 6, paragraphe 1, point e) du RGPD).",
        "Aucun transfert de données à des tiers ou d'autres évaluations n'a lieu, sauf obligation légale de le faire (article 6, paragraphe 1, point e) du RGPD)."],
    usage_data_bullets_heading: "Plus spécifiquement, l'ensemble de données suivant est stocké pour chaque récupération",
    usage_data_bullets: [
        "Nom du fichier récupéré",
        "Date et heure de la récupération",
        "Quantité de données transférées",
        "Notification si la récupération a été réussie",
        "Description du type de navigateur Web utilisé",
        "Système d'exploitation utilisé",
        "La page précédemment visitée",
        "Fournisseur",
        "Votre adresse IP"
    ],
    personal_data: "Données Personnelles",
    personal_data_desc: "Les données personnelles ne sont traitées que si cela est légalement permis ou si vous avez donné votre consentement.",
    specifically: "Plus spécifiquement",
    personal_data_bullets: [
        {
            heading: "Contact",
            descriptions: [
                "Lorsque vous nous contactez, nous stockons vos données sur la base de l'article 6, paragraphe 1, point b) du RGPD dans le but de traiter votre demande, ainsi que dans le cas où une correspondance ultérieure devrait avoir lieu."
            ]
        },
        {
            heading: "Fonction de Commentaire",
            descriptions: [
                "Vous avez la possibilité de laisser des commentaires individuels sur des articles spécifiques de blog ou de livre d'or publiés sur notre site Web.",
                "Si vous laissez un tel commentaire, en plus du contenu du commentaire lui-même, l'heure de saisie du commentaire est également stockée et publiée.",
                "De plus, votre adresse IP sera également enregistrée. Ceci est fait à des fins de preuve et de documentation dans le cas où nous serions tenus responsables de violations légales en raison de votre commentaire. Par conséquent, le traitement des données est nécessaire pour sauvegarder nos intérêts légitimes conformément à l'article 6, paragraphe 1, point f) du RGPD.",
                "Vos données personnelles ne seront transmises à des tiers que si nous y sommes légalement obligés (alors sur la base de l'article 6, paragraphe 1, point e) du RGPD) ou si cela est nécessaire pour protéger les intérêts légitimes du tiers (alors sur la base de l'article 6, paragraphe 1, point f) du RGPD)."
            ]
        }
    ],
    cookies: "Cookies",
    cookies_descriptions: [
        "Nous utilisons des cookies dans certaines parties de notre site Web. Ces éléments de fichier peuvent identifier votre ordinateur comme une unité technique lors de votre visite sur ce site Web pour faciliter l'utilisation de nos offres lors de visites ultérieures.",
        "Cependant, vous avez généralement la possibilité de configurer votre navigateur Internet pour qu'il vous informe de l'apparition de cookies, afin que vous puissiez les autoriser ou les exclure, ou supprimer les cookies déjà existants.",
        "Veuillez utiliser la fonction d'aide de votre navigateur Internet pour obtenir des informations sur la modification de ces paramètres. Veuillez noter que certaines fonctions de notre site Web peuvent ne pas fonctionner si vous avez désactivé l'utilisation des cookies.",
        "Les cookies ne permettent pas à un serveur de lire des données privées depuis votre ordinateur ou des données stockées par un autre serveur. Ils ne causent aucun dommage à votre ordinateur et ne contiennent aucun virus.",
        "Nous fondons l'utilisation des cookies sur l'article 6, paragraphe 1, point f) du RGPD : le traitement est effectué pour améliorer la fonctionnalité de notre site Web. Il est donc nécessaire de sauvegarder nos intérêts légitimes."
    ],
    usage_social_media: "Utilisation de Plugins Sociaux de Facebook, Twitter, Google+ et Instagram",
    usage_social_media_descriptions: [
        "La protection de vos données personnelles nous tient à cœur. Par conséquent, nous n'implémentons pas directement les différents plugins sociaux.",
        "Sur nos pages, nous ne mettons en place que des liens vers ces réseaux sociaux. Les données ne seront transférées que si vous suivez les liens et que les plugins sociaux correspondants sont activés.",
        "Veuillez noter que les données ne sont transférées que lorsque vous activez les plugins sociaux correspondants en cliquant sur le lien correspondant. La simple visite de nos pages n'entraîne aucune transmission de données."
    ],
    usage_social_media_bullets_heading: "Si vous activez la transmission de données en cliquant sur le lien correspondant, le transfert de données suivant se produira",
    usage_social_media_bullets: [
        {
            heading: "Facebook",
            descriptions: [
                `Des plugins du réseau social Facebook (Facebook Inc., 1601 Willow Road, Menlo Park, CA 94025, USA) sont alors intégrés sur notre site. Vous pouvez reconnaître les plugins Facebook par le logo Facebook ou le bouton "J'aime" ("Like") sur notre site. Un aperçu des plugins Facebook se trouve ici : http://developers.facebook.com/docs/plugins/.`,
                `Une connexion directe est établie entre votre navigateur et le serveur Facebook via le plugin. Facebook reçoit ainsi l'information que vous avez visité notre site avec votre adresse IP. Si vous cliquez sur le bouton "J'aime" de Facebook alors que vous êtes connecté à votre compte Facebook, vous pouvez lier le contenu de notre site à votre profil Facebook. Cela permet à Facebook d'associer la visite de notre site à votre compte.`,
                `Nous tenons à souligner qu'en tant qu'exploitants du site, nous n'avons aucune connaissance du contenu des données transmises ou de leur utilisation par Facebook. Vous trouverez de plus amples informations dans la politique de confidentialité de Facebook à l'adresse http://fr-fr.facebook.com/policy.php.`,
                `Si vous ne souhaitez pas que Facebook associe votre visite à notre site à votre compte Facebook, veuillez vous déconnecter de votre compte Facebook ou ne pas activer les plugins sociaux.`
            ]
        },
        {
            heading: "Google+1",
            descriptions: [
                `Les fonctions du service Google+ sont intégrées à notre site. Ces fonctions sont fournies par Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.`,
                `En utilisant Google+ et le bouton Google, le navigateur établit une connexion directe avec les serveurs de Google. Le contenu du bouton Google est transmis directement de Google à votre navigateur et intégré au site web.`,
                `Nous n'avons aucune influence sur la quantité de données que Google collecte avec le bouton.`,
                `Pour plus d'informations sur la finalité et l'étendue de la collecte de données, le traitement ultérieur et l'utilisation des données par Google, ainsi que sur vos droits et les options de paramétrage pour protéger votre vie privée, veuillez consulter la politique de confidentialité de Google sur le bouton Google : http://www.google.com/intl/fr/+/policy/+1button.html et la FAQ : http://www.google.com/intl/fr/+1/button/.`
            ]
        },
    ],
    use_pulgin_youtube: "Utilisation des plugins sociaux de YouTube",
    use_pulgin_youtube_descriptions: [
        `Notre site web propose également des liens vers le réseau social YouTube. Nous exploitons également une page de médias sociaux ici.`,
        `Si vous cliquez sur un lien vers YouTube, vous serez redirigé vers la page externe correspondante sur YouTube. Si vous êtes également connecté en tant que membre de YouTube, l'opérateur, YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, USA, peut attribuer votre visite sur notre page à votre compte d'utilisateur respectif. Nous tenons à souligner que YouTube LLC fait partie de Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.`,
        `Si vous ne souhaitez pas que YouTube collecte et stocke des données sur votre visite sur notre site web, vous devez vous déconnecter de votre compte YouTube avant de cliquer sur le lien.`,
        `Pour plus d'informations sur la finalité et l'étendue de la collecte de données, le traitement ultérieur et l'utilisation de vos données par YouTube, veuillez consulter la politique de confidentialité correspondante, que vous trouverez ici : http://www.google.fr/intl/fr/policies/privacy/.`,
        `Nous basons l'utilisation de YouTube sur l'art. 6(1)(a) du RGPD. En cliquant sur le lien tout en étant connecté à YouTube, vous nous donnez votre consentement au traitement des données. Dans tous les cas, le traitement des données est autorisé conformément à l'art. 6(1)(f) du RGPD ; en cliquant sur le lien alors que vous êtes connecté au réseau social concerné, le traitement des données est effectué à des fins publicitaires et est donc dans notre intérêt légitime.`
    ],
    usage_google_utility: "Utilisation des polices Web Google, de Google Maps et d'OpenStreetMap",
    usage_google_utility_bullets: [
        {
            heading: "Utilisation des polices Web Google et de Google Maps",
            descriptions: [
                "Notre site web utilise des polices externes, appelées Google Web Fonts. Nous utilisons également Google Maps pour afficher l'itinéraire d'accès à notre société et simplifier votre organisation de voyage.",
                "À cette fin, nous utilisons les services de Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
                "Lorsque vous accédez à notre page, votre navigateur charge les informations nécessaires à partir du serveur Google situé aux États-Unis dans son cache. Cela est nécessaire pour que votre navigateur affiche une représentation visuelle améliorée de nos textes, ou pour que la carte s'affiche sur notre site web.",
                "Cela permet de transmettre au serveur Google les pages Internet que vous avez consultées. Votre adresse IP est également stockée par Google.",
                "Vous trouverez plus d'informations sur les polices Web Google à l'adresse suivante : https://developers.google.com/fonts/faq?hl=fr&csw=1.",
                "Vous trouverez plus d'informations sur Google Maps à l'adresse suivante : https://www.google.com/intl/fr/help/terms_maps.html.",
                "Vous trouverez des informations générales sur la protection des données chez Google à l'adresse suivante : www.google.com/policies/privacy/"
            ]
        },
        {
            heading: "Utilisation de Google Analytics",
            descriptions: [
                "Ce site web utilise Google Analytics, un service d'analyse web fourni par Google Inc.",
                "Google Analytics utilise des fichiers texte stockés sur votre ordinateur pour aider à analyser la façon dont vous utilisez le site web.",
                "Les informations générées par le cookie concernant votre utilisation de ce site web sont généralement transmises et stockées par Google sur des serveurs situés aux États-Unis.",
                "Si l'anonymisation IP est activée sur ce site web, votre adresse IP sera tronquée par Google au sein des États membres de l'Union européenne ou dans d'autres États contractants de l'accord sur l'Espace économique européen.",
                "Ce n'est que dans des cas exceptionnels que l'adresse IP complète sera transmise à un serveur Google aux États-Unis et tronquée là-bas.",
                "Au nom de l'exploitant de ce site web, Google utilisera ces informations pour évaluer votre utilisation du site web, compiler des rapports sur l'activité du site et fournir d'autres services liés à l'utilisation du site web et d'Internet à l'exploitant du site web.",
                "L'adresse IP transmise par votre navigateur dans le cadre de Google Analytics ne sera pas fusionnée avec d'autres données de Google.",
                "Vous pouvez refuser l'utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur. Toutefois, veuillez noter que si vous le faites, il se peut que vous ne puissiez pas utiliser toutes les fonctionnalités de ce site web.",
                "Vous pouvez également empêcher la collecte des données générées par le cookie concernant votre utilisation du site web (y compris votre adresse IP) par Google et le traitement de ces données par Google en téléchargeant et en installant le plug-in de navigateur disponible à l'adresse http://tools.google.com/dlpage/gaoptout?hl=fr."
            ]
        }
    ],
    duration_storage: "Durée de conservation",
    duration_storage_desc: "Nous conservons vos données aussi longtemps que nécessaire pour le traitement final de votre demande. Sont exclus de ceci les données pour lesquelles existent des obligations légales ou autrement prescrites de conservation ; celles-ci sont conservées pendant la durée de la période de conservation respective et ensuite supprimées de manière routinière.",
    rights_data_subject: "Droits de la personne concernée",
    rights_data_subject_bullets: [
        {
            heading: "Droit d'accès",
            description: "Vous avez le droit de nous demander confirmation du traitement de données à caractère personnel vous concernant.",
            footer: "Pour cela, il vous suffit d'envoyer un courriel à bobfel@arcor.de"
        },
        {
            heading: "Rectification/Effacement/Limitation du traitement",
            description: "Vous avez également le droit de nous demander que",
            bullets: [
                "les données à caractère personnel inexactes vous concernant soient corrigées sans délai (droit de rectification) ;",
                "les données à caractère personnel vous concernant soient effacées sans délai (droit à l'effacement) ; et",
                "le traitement soit limité (droit à la limitation du traitement)."
            ],
            footer: "Pour cela, il vous suffit d'envoyer un courriel à bobfel@arcor.de"
        },
        {
            heading: "Droit à la portabilité des données",
            description: "Vous avez le droit de recevoir les données à caractère personnel vous concernant, que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible par machine, et de transmettre ces données à un autre responsable du traitement.",
            footer: "Pour cela, il vous suffit d'envoyer un courriel à bobfel@arcor.de"
        },
        {
            heading: "Droit de retrait du consentement",
            description: "Vous avez le droit de retirer votre consentement à tout moment. Le retrait du consentement ne compromet pas la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci.",
            footer: "Pour cela, il vous suffit d'envoyer un courriel à bobfel@arcor.de"
        },
        {
            heading: "Droit d'opposition",
            description: "Lorsque le traitement de données à caractère personnel vous concernant est nécessaire à l'exécution d'une mission d'intérêt public (art. 6, paragraphe 1, alinéa e) du RGPD) ou aux fins de intérêts légitimes (art. 6, paragraphe 1, alinéa f) du RGPD), vous avez le droit de vous opposer à ce traitement.",
            footer: "Pour cela, il vous suffit d'envoyer un courriel à bobfel@arcor.de"
        },
        {
            heading: "Droit de réclamation",
            description: "Si vous estimez que le traitement de données à caractère personnel vous concernant est contraire au RGPD, vous avez le droit d'introduire une réclamation auprès d'une autorité de contrôle, sans préjudice de tout autre recours administratif ou judiciaire."
        }
    ],
    security: "Sécurité",
    security_desc: "Nous utilisons les dernières technologies internet pour la sécurité de vos données. Afin de stocker vos données en toute sécurité, nos systèmes sont protégés par des pare-feux qui empêchent tout accès non autorisé depuis l'extérieur.",
    video_selected: "video sélectionnée",
    "reports": "Rapports",
    video_title: "Titre de la Vidéo",
    downloads: "Téléchargements",
    views: "Vues",
    uploaded_on: "Téléchargé le",
    export_to_csv: "Exporter en CSV",
    reports: "Rapports",
    emails: "Courriels",
    download: "Télécharger",
    download_by: "Téléchargé par",
    download_on: "Téléchargé le",
    view_by: "Vu par",
    view_on: "Vu le",

    labels: {
        name: "Nom",
        joining_date: "Date d’adhésion",
        email: "Messagerie électronique",
        date_or_time: "Date/Time",
        subject: "Objet",
        body: "Corps",
        recipients: "Destinataires",
        attachments: "Pièces jointes",
        send: "Envoyer",
        cancel: "Annuler",
        clear: "Effacer",
        delete: "Supprimer",
        search: "Rechercher",
        select_all: "Tout sélectionner",
        unselect_all: "Tout désélectionner",
        view_all: "Voir tout",
        view: "Vue",
        view_less: "Voir moins",
        download: "Télécharger",
        compose_email: "Composer un email",
        load_more: "Charger plus",
        new_email: "Nouvel email",
        email_preview: "Aperçu de l'email",
        no_record_found: "Aucun document trouvé",
        refech: "Refech",
        export_to_csv: "Exporter au format CSV",
    },
    placeholders: {
        email_subject: "Objet de l'email",
        email_content: "Contenu de l'email ici",
        recipients: "Entrez les destinataires",
        attachments_dragdrop_msg: "Glisser-déposer les fichiers ici ou cliquer pour sélectionner des fichiers",
        search_placeholder_songs: "Search artists, songs, albums...",
        attachments_size_msg: "Taille maximale de téléchargement (20 Mo)",
        attachments_support_files_msg: "Fichiers pris en charge: .jpg, .png, .gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .mp3, .mp4",
        search_placeholder_video: "Rechercher vidéos...",
    },
    errors: {
        email_subject_error: "L'objet ne peut pas être vide",
        email_body_error: "Le corps de l'email ne peut pas être vide",
        participants_error: "Les participants ne peuvent pas être vides",
        invalid_file_type_only_csv_allowed: "Type de fichier non valide. Veuillez télécharger un fichier CSV.",

    }

}
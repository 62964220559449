import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTab } from '../../../redux/slice/ui/ui-slice';
import styles from '../../../styles/page-styles/admin/songs.module.scss';
import { SearchBox } from '../../../components/general/input/search-box';
import { Table } from '../../../components/general/table';
import { useState } from 'react';
import { List } from '../../../components/general/list';
import { getNewSongsAsync, searchSongAsync, selectSongs, selectTotalPages, setSongsData } from '../../../redux/slice/song/song-slice';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { constants } from '../../../constants';
import { PAGE_LIMIT_FOR_NEW_SONGS_LIST } from '../../../utils/constants';
export function NewSongs() {
    const totalPage = useSelector(selectTotalPages)
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const songs = useSelector(selectSongs)
    const [sortBy, setSortBy] = useState("")
    const [query, setQuery] = useState("")
    const onSort = (field) => {
        setCurrentPage(1)
        setSortBy(field)
        dispatch(getNewSongsAsync({ start: (0) * PAGE_LIMIT_FOR_NEW_SONGS_LIST, limit: PAGE_LIMIT_FOR_NEW_SONGS_LIST, sortBy: field }));
    }
    useEffect(() => {
        dispatch(updateTab({ tab: 'New Songs' }))
        if (!songs || songs.length === 0) {
            dispatch(getNewSongsAsync({ start: (0) * PAGE_LIMIT_FOR_NEW_SONGS_LIST, limit: PAGE_LIMIT_FOR_NEW_SONGS_LIST, sortBy }))
        }
        return () => {
            dispatch(setSongsData(null))
        }
    }, [])
    useEffect(() => {
        dispatch(getNewSongsAsync({ start: (currentPage - 1) * PAGE_LIMIT_FOR_NEW_SONGS_LIST, limit: PAGE_LIMIT_FOR_NEW_SONGS_LIST, sortBy, append: true }))
    }, [currentPage])



    return <div className={styles.songs}>

        <>
            <div className={styles.table}>
                <Table songs={songs}
                    // start_index={(0) * 8} end_index={currentPage * 8}
                    isSortable={true}
                    sortBy={sortBy}
                    onSort={onSort}
                    currentPage={currentPage}
                    totalPages={totalPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            {/* <span className={styles.pagination}>
                {songs && songs.length > 0 && <Pagination totalPages={totalPage}
                    currentPage={currentPage} onPageChange={onPageChange}
                />}
            </span>
            <span className={styles.mobilePagination}>
                {songs && songs.length > 0 && <Pagination totalPages={totalPage}
                    currentPage={currentPage} onPageChange={onPageChange} max={4}
                />}
            </span> */}
        </>
    </div>
}